<template>
  <div class="success-wrapper text-center" :class="{ 'with-visitor-id-card-template': hasVisitorIdCardTemplate }">
    <v-icon
      :icon="mdiAccountCheck"
      :size="100"
      color="success"
      class="mb-4"
    />
    <h1 class="view-heading text-h5 mb-4">{{ $translate('thanksForYourCheckin') }}</h1>
    <div class="checked-in-text px-12">
      <p
        v-if="successText"
        class="my-4"
      >
        {{ successText }}
      </p>
    </div>
    <div v-if="hasVisitorIdCardTemplate" class="visitor-id-card-template-wrapper">
      <img :src="'data:image/png;base64,' + visitStore.pendingVisitVisitorIdCardBase64" />
    </div>
    <div class="bottom-content px-4">
      <p
        class="info-text mt-4 px-10"
      >
        {{ $translate('CheckedInInfo') }}
      </p>
      <v-dialog width="500">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="red"
            elevation="0"
            size="large"
            min-width="70%"
            class="mt-10"
            :text="$translate('checkout')"
            data-qa="checkout-button"
          />
        </template>

        <template #default="{ isActive }">
          <v-card>
            <v-card-text>
              <p>{{ $translate('checkoutConfirmMessage') }}</p>
            </v-card-text>

            <v-card-actions>
              <v-btn
                variant="flat"
                @click="isActive.value = false"
              >
                {{ $translate('cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                variant="flat"
                data-qa="checkout-confirm-button"
                @click="checkout"
              >
                {{ $translate('yesCheckout') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVisitConditionsStore } from '@/store/visit-conditions';
import type { IVisitFormTextSection } from '@einfachgast/shared';
import { computed } from 'vue';
import { translate } from '@/plugins/translator';
import { mdiAccountCheck } from '@mdi/js';
import { useVisitStore } from '@/store/visit';

const visitStore = useVisitStore();

const visitConditionsStore = useVisitConditionsStore();
const successSection = computed(() => visitConditionsStore.visitSettings?.visitFormSchema.sections.find((section) => section.type === 'successText'));
const successText = computed(() => translate((successSection.value as IVisitFormTextSection)?.content));
const emits = defineEmits(['checkout']);
const checkout = () => {
  emits('checkout');
};

const hasVisitorIdCardTemplate = computed(() => !!visitStore.pendingVisitVisitorIdCardBase64);
</script>
<style lang="scss" scoped>
.success-wrapper {
  margin-top: 25vh;
}
.success-wrapper.with-visitor-id-card-template {
  margin-top: 0vh;
}
.bottom-content {
  position: fixed;
  bottom: 60px;
  width: 100%;
  left: 0;
}
.info-text {
  color: #9e9e9e;
}
.visitor-id-card-template-wrapper {
 
 margin: 20px 50px;
}
.visitor-id-card-template-wrapper img {
  width: 100%;
  height: 100%;
  max-width: 300px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #ccc;
}
</style>

